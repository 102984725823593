<template>
  <div class="terms-wrapper">
    <div class="terms-title">Terms of use</div>
    <p>
      <b>Terms of use of the Bible School website WARNING!</b> <br> Please read these Terms carefully before viewing the
      Site. If you do not agree to these Terms, do not use the Site.
    </p>

    <p>
      Use of the Site The Site allows you to view and download materials from it
      (hereinafter referred to as the "Site") only for personal
      non-commercial use, provided that you retain all copyright and other
      proprietary information contained in the source materials and any
      copies thereof. It is prohibited to modify the materials of this Site,
      as well as to distribute or display them in any form or use them in
      any other way for commercial purposes. Disclaimer The materials and
      services of this site are provided without any guarantees and any
      responsibility for them on the part of the owners and administration
      of the Site.
      </p>
      <p>
      The Site does not guarantee the accuracy and completeness
      of the materials, programs and services provided on this Site. Changes
      to the materials and services provided on this Site may be made at any
      time without notice. Under no circumstances shall the owners and
      administration of the Site be liable for any damages resulting from
      the use, inability to use or results of the use of this site.
      Registration on the site By registering on the Site, you agree to
      provide reliable information about yourself and your contact
      information.
    </p>
    <p>
      Feedback and comments By contacting us, you are
      responsible for the fact that this message is not illegal, harmful,
      threatening, defamatory, contrary to moral norms, infringes copyright,
      promotes hatred and / or discrimination against people on racial,
      ethnic, sexual, religious, social grounds, contains insults to the
      address of specific persons or organizations, as well as in any other
      way violates the current legislation of Ukraine. You agree that any of
      your messages may be deleted by the Site Administration without your
      consent, as well as free of charge at your own discretion. The owners
      and administration of the Site are not responsible for any information
      posted by users of the Site. Use of personal data We use various
      technologies to collect and store information when you visit the Site.
    </p>

    <p>
      This may include recording one or more cookies or anonymous
      identifiers. We also use cookies and may use anonymous identifiers
      when you interact with services offered by our partners, such as
      advertising services, such as those that may appear on other sites.
    </p>
  </div>
</template>
<style scoped>
.terms-wrapper {
  max-width: 1218px;
  padding: 60px 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.terms-title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  padding: 40px;
}
.terms-wrapper p {
  padding: 10px;
  font-size: 20px;
  line-height: 30px;
}
.terms-wrapper b {
  font-weight: 600;
}
</style>
